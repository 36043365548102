import React, { PureComponent } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Carousel } from 'react-responsive-carousel';
import styles from '../styles/Gallery.module.css';

export default class Gallery extends PureComponent {
  componentDidMount() {
    document.body.style = 'padding-top: 80px';
  }

  componentDidUpdate() {}

  render() {
    return (
      <div className={styles.sectionDiv}>
        <StaticQuery
          query={graphql`
            query {
              allContentfulGallery {
                edges {
                  node {
                    title
                    description
                    file {
                      file {
                        url
                        fileName
                        contentType
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data => {
            const images = data.allContentfulGallery.edges.map(e => {
              return (
                <div key={e.node.title} id={styles.imageDiv}>
                  <img
                    alt={e.node.title}
                    src={'https:' + e.node.file.file.url}
                  />
                  <p className="legend">{e.node.description}</p>
                </div>
              );
            });

            return (
              <div className={styles.galleryContainer}>
                <div className={styles.galleryDiv}>
                  <Carousel id={styles.carousel} dynamicHeight={true}>
                    {images}
                  </Carousel>
                </div>
              </div>
            );
          }}
        />
      </div>
    );
  }
}
