import React from 'react';
import Layout from '../components/layout';
import Gallery from '../components/gallery/Gallery';

export default function gallery() {
  return (
      <Layout>
        <Gallery />
      </Layout>
  );
}
